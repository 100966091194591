@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Playfair Display', serif;
  color: #8F3A1A;
}

a {
  color: #8F3A1A;
}

.theme-color {
  color: #b47e66;
}

.sfondo-top {
  background-image: url('/public/img/sfondo-desktop-2.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;
}

.sfondo-center {
  background-image: url('/public/img/sfondo-desktop-1.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .sfondo-top {
    background-image: url('/public/img/sfondo-mobile-1.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }

  .sfondo-center {
    background-image: url('/public/img/sfondo-mobile-2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -2;
  }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .sfondo-bottom {
    background-image: url('/public/img/sfondo-mobile-bottom.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-elegance {
  font-family: 'Dancing Script', cursive;
}

.font-serif {
  font-family: 'Playfair Display', serif;
}

.sepia-filter {
  filter: sepia(50%);
}

.btn-EN {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0;
  border: solid 2px #8F3A1A;
  color: #8F3A1A;
  transition: 0.4s;
  background-color: white;
}

.btn-EN:hover {
  background-color: #8F3A1A;
  color: white;
}

.form-control {
  border: solid 2px black;
  border-radius: 0;
}

.form-select {
  border: solid 2px black;
  border-radius: 0;
}

.fs-3rem {
  font-size: 3rem;
}

.fs-6rem {
  font-size: 6rem;
}

.fs-7rem {
  font-size: 7rem;
}

.bg-semi-transparent {
  background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.53),
          rgba(255, 255, 255, 0)
  );
}

input, select, textarea {
  background-color: rgba(255, 255, 255, 0.53)!important;
  /*border: solid 2px #8F3A1A!important;*/
}

.form-check-input {
  border: solid 2px black;
  background-color: black;
}

input:checked {
  background-color: saddlebrown!important;
  color: black;
  border: white;
}

.custom-row {
  background-color: white;
  padding: 10px; /* Opzionale: aggiungi padding per evidenziare l'effetto gradiente */
}

.custom-row:before,
.custom-row:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px; /* Larghezza del gradiente, puoi regolarla secondo le tue esigenze */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white); /* Imposta il gradiente, regola il colore e l'opacità */
}

.custom-row:before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white); /* Imposta il gradiente sul bordo sinistro */
}

.custom-row:after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white); /* Imposta il gradiente sul bordo destro */
}
